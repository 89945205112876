import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import UserContextRoute from '../components/util/UserContextRoute';

import ClientRouter from '../ClientRouter';
import loadable from '../util/routeUtils';

const NoMatch = loadable(() => import('../pages/NoMatch/NoMatch'));

const ZolaHomepage = loadable(() => import('../pages/ZolaHomepageV2/ZolaHomepageContainerV2'));

const BrandsLandingContainer = loadable(() => import('../pages/Brands/BrandsLandingContainer'));

const ProductDetailPage = loadable(() => import('../pages/ProductDetailPage/ProductDetailPage'));

const KitProductDetailPage = loadable(
  () => import('../pages/KitProductDetailPage/KitProductDetailPage')
);

const ShopSearchLandingContainer = Loadable({
  loader: () => import('../pages/ShopPages/ShopSearchLandingContainer'),
  loading: () => <Fragment />,
});

const CollectionsLandingContainer = Loadable({
  loader: () => import('../pages/ShopPages/CollectionsLandingContainer'),
  loading: () => <Fragment />,
});

const CategoryLandingContainer = Loadable({
  loader: () => import('../pages/ShopPages/CategoryLandingContainer'),
  loading: () => <Fragment />,
});

const FeaturedBrandsLandingContainer = Loadable({
  loader: () => import('../pages/ShopPages/FeaturedBrandsLandingContainer'),
  loading: () => <Fragment />,
});

const ShopHomeLandingContainer = Loadable({
  loader: () => import('../pages/ShopPages/ShopHomeLandingContainer'),
  loading: () => <Fragment />,
});

const SaleLandingContainer = Loadable({
  loader: () => import('../pages/ShopPages/SaleLandingContainer'),
  loading: () => <Fragment />,
});

const ShopRoutes = () => (
  <ClientRouter>
    <Switch>
      <UserContextRoute exact path="/" component={ZolaHomepage} />
      <Route exact path="/shop/brands/:categoryKey?" component={BrandsLandingContainer} />
      <Route exact path="/shop/index" component={ShopHomeLandingContainer} />
      <Route exact path="/shop/sale" component={SaleLandingContainer} />
      <Route
        exact
        path="/shop/collection/:collectionKey?"
        component={CollectionsLandingContainer}
      />
      <Route exact path="/shop/category/:categoryKey?" component={CategoryLandingContainer} />
      <Route exact path="/shop/brand/:brandKey?" component={FeaturedBrandsLandingContainer} />
      <Route exact path="/search/gifts" component={ShopSearchLandingContainer} />

      <Route exact path="/shop/product/:productLookKey" component={ProductDetailPage} />
      <Route exact path="/shop/kit/:productKey" component={KitProductDetailPage} />
      <Route component={NoMatch} />
    </Switch>
  </ClientRouter>
);

export default ShopRoutes;
